import {
    ErrorType,
    LFService,
    Logger,
    LoggerFactory,
    LoggerFactoryOptions,
    LogGroupRule,
    LogLevel,
    LogMessage,
    MessageFormatUtils,
    MessageType,
} from 'typescript-logging';

export { Logger, LoggerFactory, LogLevel, MessageType, ErrorType };

let _uuiLoggerFactory: LoggerFactory;
let _uuiLogLevel: LogLevel;
let _maxLogMessageLength: number;
let _appType: string;
let errorListenersInitialized = false;

declare global {
    interface Window {
        // TODO: export type from OCjs and remove this when OCjs-common is moved inside OCjs
        ocInfo: { applicationType: () => Promise<string> };
    }
}

/**
 * Returns a logger to be used to log messages.
 * @param loggerName The name of the logger. All loggerNames will be prefixed with UUI. (ex: redux-action will show up in logs as UUI.redux-logger)
 * @returns Logger instance
 */
export const getLogger = (loggerName: string): Logger => {
    if (!_uuiLoggerFactory) {
        initializeLoggerFactory();
    }
    return _uuiLoggerFactory.getLogger(loggerName) as Logger;
};

export const getCurrentLogLevel = (): LogLevel => {
    return _uuiLogLevel;
};

export const getMaxLogMessageLength = (): number => _maxLogMessageLength;

/**
 * Creates a loggerFactory that getLogger will use. You can call this function again to change the system wide logging level.
 * @param logLevel The level of logging desired
 * @param maxLogMessageLength Any messages longer than this will be truncated and ellipsed. Default is 1000
 */
export const initializeLoggerFactory = (logLevel: LogLevel = LogLevel.Error, maxLogMessageLength = 1000): void => {
    const options = new LoggerFactoryOptions();
    if (isOC()) {
        void window.ocInfo.applicationType().then((appType: string) => (_appType = appType));
    }

    options.addLogGroupRule(buildLogGroupRule('.*', logLevel));

    _uuiLogLevel = logLevel;
    _uuiLoggerFactory = LFService.createLoggerFactory(options);
    _maxLogMessageLength = maxLogMessageLength;

    if (!errorListenersInitialized) {
        window.addEventListener('error', (e: ErrorEvent) => {
            const errorMessage = e instanceof CustomEvent ? `${e.detail.detailedMessage} ${e.detail.message}` : e.message;
            getLogger('global-error-handler').error(errorMessage);
        });

        window.addEventListener('unhandledrejection', (e: PromiseRejectionEvent) => {
            getLogger('promise-rejection-handler').error(e.reason.toString());
        });
        errorListenersInitialized = true;
    }
};

const getMessageContent = (message: LogMessage) => {
    if (message.message instanceof CustomEvent) {
        return message.message.detail;
    } else if (typeof message.message === 'string') {
        return truncateStringForLogging(message.message);
    } else if (message.message && 'msg' in message.message && 'data' in message.message) {
        return truncateStringForLogging(`${message.message.msg} ${JSON.stringify(message.message.data)}`);
    } else {
        return truncateStringForLogging(JSON.stringify(message.message));
    }
};

const buildLogGroupRule = (pattern: string, logLevel: LogLevel) => {
    const rule = new LogGroupRule(new RegExp(pattern), logLevel);
    rule.formatterLogMessage = (message: LogMessage) => {
        
        const processParts = _appType ? `| ${_appType} | [PROCESS ID]` : '';
        const dateStr = MessageFormatUtils.renderDate(message.date, message.logGroupRule.logFormat.dateFormat);
        const logLevelStr = LogLevel[message.level].toUpperCase();
        const loggerName = message.loggerName;

        const messageContent = message.message instanceof CustomEvent
            ? message.message.detail
            : typeof message.message === 'string'
                ? truncateStringForLogging(message.message)
                : truncateStringForLogging(getMessageContent(message));
        
        
        let logEntry = `${dateStr} ${processParts} | ${logLevelStr} | ${loggerName} | ${messageContent}`;

        if (message?.error) {
            logEntry = logEntry + `\r\n${message.errorAsStack}`;
        }

        return logEntry;
    };
    return rule;
};

/**
 * Truncates a string to 1000 characters and adds an ellipsis if string is truncated.
 *
 * @param str String to apply truncation logic to
 */
const truncateStringForLogging = (str: string): string =>
    str?.length > _maxLogMessageLength ? str.substring(0, _maxLogMessageLength) + '...' : str;

const isOC = (): boolean => navigator?.userAgent?.includes('Electron');
