import { useEffect } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { ERROR_403_MESSAGE, FAILED_FETCH_MESSAGE } from '../../../utils/fetchUtils';


export const useGlobalErrorHandler = (): void => {
    const errorHandler = useErrorHandler();

    useEffect(() => {
        const ignoredErrorMessages = [FAILED_FETCH_MESSAGE,ERROR_403_MESSAGE];

        const errorIsHandledByDifferentOverlay = (e: ErrorEvent) => {
            const message = e instanceof CustomEvent ? e.detail?.detailedMessage : e.message;
            return ignoredErrorMessages.find((m) => message.includes(m) || m.includes(message)) !== undefined;
        };

        const errorEventListener = (e: ErrorEvent) => {
            const isPowerBIElement = e.target && (e.target as HTMLElement).classList.contains('powerBIReportContainer');
            console.log('customErrorEventListner', e, isPowerBIElement);

            if (!errorIsHandledByDifferentOverlay(e) && !isPowerBIElement) {
                if (e.message.includes('ResizeObserver loop')) {
                    return;
                }
                errorHandler(e);
            }
        };    

        const unhandledRejectionListener = (e: PromiseRejectionEvent) => {
            if (!errorIsHandledByDifferentOverlay(e.reason as ErrorEvent)) {
                errorHandler(e.reason);
            }
        };

        window.addEventListener('error', errorEventListener);
        window.addEventListener('unhandledrejection', unhandledRejectionListener);

        return () => {
            window.removeEventListener('error', errorEventListener);
            window.removeEventListener('unhandledrejection', unhandledRejectionListener);
        };
    }, [errorHandler]);
};